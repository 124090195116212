<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>满减列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrapped">
      <div v-if="showSearch">
        <el-input
          placeholder="手机号"
          style="width: 200px; margin-top: 5px"
          oninput="value=value.replace(/[^\d]/g,'')"
          v-model="phoneNumber"
          size="mini"
        >
          <el-button
            @click="searchBusinessInfoByPhoneNumber()"
            slot="append"
            icon="el-icon-search"
          />
        </el-input>
        <el-select
          v-model="shop"
          placeholder="店铺"
          filterable
          remote
          reserve-keyword
          :remote-method="remoteMethod"
          :loading="loading"
          style="width: 125px"
          size="mini"
          clearable
          @change="handleShop"
        >
          <el-option
            v-for="item in shopList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="status"
          placeholder="状态"
          style="width: 125px"
          size="mini"
          clearable
          @change="handleStatus"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        class="tableWrapped"
      >
        <el-table-column fixed prop="limitAmount" label="满减限制" width="180">
        </el-table-column>
        <el-table-column prop="businessName" label="店铺名" width="180">
        </el-table-column>
        <el-table-column prop="discountAmount" label="折扣金额">
        </el-table-column>

        <!-- <el-table-column label="剩余数量">
          <template slot-scope="scope">
            <span>
              {{ scope.row.totalLimitCount - scope.row.receivedCount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="totalLimitCount" label="总数量">
        </el-table-column> -->
        <el-table-column label="生效时间" width="300">
          <template slot-scope="scope">
            {{ scope.row.startTime }} 到 {{ scope.row.endTime }}
          </template>
        </el-table-column>

        <!-- <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">
              编辑
            </el-button>
            <div v-if="scope.row.operateItemShow">
              <el-button
                type="text"
                size="small"
                class="editItem"
                @click="handleEditNumber(scope.row)"
                >修改数量</el-button
              >
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="couponsearchInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="couponsearchInfo.total"
      >
      </el-pagination>
      <el-dialog
        width="30%"
        title="修改剩余数量"
        append-to-body
        :visible.sync="dialogVisible"
      >
        <div>
          <span>修改值为：</span>
          <input
            v-model="modifyNumberValue"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="填写数量"
          />
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="modifyNumber">确 认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  fullReduction,
  searchBusinessInfoByUserId,
  getBusinessInfoByPhoneNumber,
  modifyBusinessInfoNumber,
  searchBusinessInfoByMobile,
  getShopList,
} from "../../api/index";
export default {
  data() {
    return {
      tableData: [
        {
          name: "优惠券1",
          limitAmount: 100,
          discountAmount: 90,
          everyoneLimitCount: 1,
          totalLimitCount: 10,
          // operateItemShow: (value = false) => value,
          operateItemShow: false,
          receivedCount: 1,
          startTime: "2024-03-04 00:00:00",
          endTime: "2024-03-05 23:59:59",
          id: 1,
        },
        {
          name: "优惠券1",
          limitAmount: 100,
          discountAmount: 90,
          everyoneLimitCount: 1,
          totalLimitCount: 10,
          // operateItemShow: (value = false) => value,
          operateItemShow: false,
          receivedCount: 1,
          startTime: "2024-03-04 00:00:00",
          endTime: "2024-03-05 23:59:59",
          id: 2,
        },
        {
          name: "优惠券1",
          limitAmount: 100,
          discountAmount: 90,
          everyoneLimitCount: 1,
          totalLimitCount: 10,
          // operateItemShow: (value = false) => value,
          operateItemShow: false,
          receivedCount: 1,
          startTime: "2024-03-04 00:00:00",
          endTime: "2024-03-05 23:59:59",
          id: 3,
        },
        {
          name: "优惠券1",
          limitAmount: 100,
          discountAmount: 90,
          everyoneLimitCount: 1,
          totalLimitCount: 10,
          // operateItemShow: (value = false) => value,
          operateItemShow: false,
          receivedCount: 1,
          startTime: "2024-03-04 00:00:00",
          endTime: "2024-03-05 23:59:59",
          id: 4,
        },
        {
          name: "优惠券1",
          limitAmount: 100,
          discountAmount: 90,
          everyoneLimitCount: 1,
          totalLimitCount: 10,
          // operateItemShow: (value = false) => value,
          operateItemShow: false,
          receivedCount: 1,
          startTime: "2024-03-04 00:00:00",
          endTime: "2024-03-05 23:59:59",
          id: 5,
        },
      ],
      phoneNumber: null,
      status: "",
      statusList: [
        { value: "0", label: "可用" },
        { value: "1", label: "失效" },
      ],
      dialogVisible: false,
      couponsearchInfo: {
        page: 1,
        total: 0,
        size: 10,
      },
      showSearch: window.sessionStorage.getItem("userIdentity") === "10",
      modifyNumberValue: null,
      shopList: [
        // { value: 1, label: "沙县小吃" },
        // { value: 2, label: "沙县超市" },
      ],
      shop: "",
      loading: false,
    };
  },
  mounted() {
    console.log(
      window.sessionStorage.getItem("businessId"),
      "fsdfsafsadfasfdsdf"
    );
    // console.log(
    //   window.sessionStorage.getItem("userIdentity"),
    //   3,
    //   typeof window.sessionStorage.getItem("userIdentity"),
    //   "window.sessionStorage.getItem(userIdentity)"
    // );
    this.searchBusinessInfoByPhoneNumber();
  },
  methods: {
    async remoteMethod(query) {
      console.log(query, "query");
      if (query !== "") {
        this.loading = true;
        const { data: res } = await getShopList({ name: query });
        this.loading = false;

        this.shopList = res.body;
        this.shopList = this.shopList.map((item) => {
          return {
            value: `${item.id}`,
            label: `${item.businessName}`,
          };
        });
      }
      // else {
      //   this.options = [];
      // }
    },
    handleSizeChange(val) {
      this.couponsearchInfo.page = 1;
      this.couponsearchInfo.size = val;
      this.searchBusinessInfoByPhoneNumber();

      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.couponsearchInfo.page = val;
      // console.log(`当前页: ${val}`);
      this.searchBusinessInfoByPhoneNumber();
    },
    async searchBusinessInfoByPhoneNumber() {
      let businessId = undefined;
      if (!this.showSearch) {
        const userId = window.sessionStorage.getItem("userId");
        const { data: businessDatas } = await searchBusinessInfoByUserId({
          userId,
        });
        businessId = businessDatas.body.id;
      }
      if (this.phoneNumber) {
        const { data: result } = await searchBusinessInfoByMobile({
          mobile: this.phoneNumber,
        });
        businessId = result.body.id;
      }
      if (this.shop) {
        // const { data: businessDatas } = await searchBusinessInfoByUserId({
        //   userId:this.shop,
        // });
        // businessId = businessDatas.body.id;
        businessId = this.shop;
      }
      console.log(typeof this.status, "this.status");
      // const formData = new FormData();
      // formData.append("status", this.status);
      // // formData.append("businessId", businessId);
      // formData.append("page", this.couponsearchInfo.page);
      // formData.append("size", this.couponsearchInfo.size);
      // console.log(formData, "formData");
      const params = {
        ...this.couponsearchInfo,
        businessId,
        status: this.status,
      };
      // const params = new URLSearchParams(formData);
      // const { data: res } = await this.$http.get(
      //   `/api/platform/businessDiscount/searchAllList?${params.toString()}`
      // );

      const { data: res } = await fullReduction(params);
      this.tableData = res.body.list;
      this.couponsearchInfo.total = res.body.total;
    },
    handleEdit(item) {
      this.tableData = this.tableData.map((item_) => {
        item.id === item_.id
          ? (item_.operateItemShow = !item_.operateItemShow)
          : "";
        // console.log(item_.operateItemShow);

        return item_;
      });
    },
    async handleEditNumber(item) {
      this.dialogVisible = true;
    },
    async modifyNumber() {
      // const res = await modifyBusinessInfoNumber();
    },
    async handleStatus() {
      this.searchBusinessInfoByPhoneNumber();
    },
    handleShop() {
      this.searchBusinessInfoByPhoneNumber();
    },
  },
};
</script>

<style>
.wrapped {
  padding: 16px 20px;

  height: 100%;
  width: calc(100vw - 280px);
  background-color: white;
}
.tableWrapped .editItem {
  /* font-size: 16px; */
  margin-left: 10px;
  /* text-decoration: none; */
}
</style>
